import React, { useState } from 'react';
import Logo from "../Images/Logo.svg";
import nav_con from "../Images/icon_nav.svg";

export default function Header() {
  const data = [
    { name: "Why Us", link: "#WhyUs", submenu: null },
    { name: "Our Features", link: "#OurFeatures", submenu: null },
    {
      name: "Signature",
      link: "#Signature",
      submenu: [
        { name: "Submenu 1", link: "#Submenu1" },
        { name: "Submenu 2", link: "#Submenu2" },
      ],
    },
    { name: "Teams", link: "#Teams", submenu: null },
    { name: "Testimonials", link: "#Testimonials", submenu: null },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  return (
    <div className="flex justify-between w-full mx-auto headerNav container-sm" style={{ paddingTop: "20px" }}>
      
      <img src={Logo} alt="Logo" className="cursor-pointer" />

      {/* Mobile Menu Icon */}
      <img 
        src={nav_con} 
        alt="nav" 
        className="mNavIcon lg:hidden cursor-pointer" 
        onClick={toggleMenu} 
        style={{ position: 'absolute', right: '20px', top: '20px' }}
      />
      
      {/* Sliding Mobile Menu */}
      <div 
        className={`fixed top-0 left-0 h-full bg-white shadow-lg transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out lg:hidden z-50`}
        style={{ width: '250px', padding: '20px' }}
      >
        {data.map((item, index) => (
          <div key={index} className="text-[#525252] text-base font-normal hover:text-black mb-4">
            {item.submenu ? (
              <>
                <div onClick={() => toggleSubMenu(index)} className="cursor-pointer">
                  {item.name}
                </div>
                {activeSubMenu === index && (
                  <div className="ml-4 mt-2">
                    {item.submenu.map((subItem, subIndex) => (
                      <div key={subIndex} className="text-[#525252] text-base font-normal hover:text-black">
                        <a href={subItem.link} onClick={() => setIsMenuOpen(false)}>{subItem.name}</a>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <a href={item.link} onClick={() => setIsMenuOpen(false)}>{item.name}</a>
            )}
          </div>
        ))}
        <div className="cntButton px-4 py-2 bg-[#0E77D3] rounded-[5px] hover:bg-[#00437E] text-[white] text-base">
          Contact Us
        </div>
      </div>

      {/* Desktop Navigation - Unchanged */}
      <div className="flex gap-8 items-center cursor-pointer navList hidden lg:flex">
        {data.map((item, index) => (
          <div key={index} className="text-[#525252] text-base font-normal hover:text-black">
            <a href={item.link}>{item.name}</a>
          </div>
        ))}
        <div className="cntButton px-4 py-2 bg-[#0E77D3] rounded-[5px] hover:bg-[#00437E] text-[white] text-base">
          Contact Us
        </div>
      </div>
    </div>
  );
}
