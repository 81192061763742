import Signature1 from "../Images/Signature1.png";
import Signature2 from "../Images/Signature2.png";
import Card2 from "../Images/Card2.png";

export default function Team() {
  return (
    <div
      className="bg-[#fff] h-auto lg:h-screen flex flex-col-reverse lg:flex-row-reverse justify-center items-center w-full gap-4 lg:py-[100px] overflow-x-hidden"
      id="Teams"
    >
      {/* Image Section */}
      <div className="w-full lg:w-[50%] flex items-center relative h-[40vh] lg:h-[60vh] lg:pe-[150px] overflow-hidden">
        <div className="absolute bottom-[5%] left-[10%] lg:left-1 lg:-bottom-20 z-10">
          <img
            src={Signature2}
            alt="Signature 2"
            data-aos="zoom-in-right"
            className="h-[150px] lg:h-[280px]"
          />
        </div>
        <div className="absolute top-[10%] left-[30%] lg:left-[28%]">
          <img
            src={Signature1}
            alt="Signature 1"
            data-aos="zoom-in-up"
            className="h-[220px] lg:h-[420px]"
          />
        </div>

        <div className="absolute top-[40%] right-[15%] lg:top-[23%] lg:right-[7%] z-10">
          <img
            src={Card2}
            alt="Card 2"
            data-aos="zoom-in-left"
            className="h-[150px] lg:h-[280px]"
          />
        </div>
      </div>

      {/* Text Section */}
      <div className="w-full lg:w-[50%] p-6 lg:p-0 overflow-hidden">
        <div className="flex flex-col gap-[12px] w-full TeamsBackground items-start lg:p-[3rem] lg:ps-[150px]">
          <div
            className="text-[#A2A2A2] text-[14px] lg:text-[16px] leading-[20.8px] font-semibold uppercase"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Modishcard for Teams
          </div>
          <div
            className="text-[28px] lg:text-[40px] leading-[36px] lg:leading-[52px] font-light"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Empower Your&nbsp;
            <span className="text-[#0E77D3] font-bold">
              Team <br />
            </span>
            with Modishcard
          </div>
          <div
            className="w-full lg:w-[70%] text-[#909090] text-[14px] lg:text-[16px] leading-[24px] tracking-wide font-normal"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Centralize your company's networking with Modishcard. Gain control
            over brand consistency, manage contacts efficiently, and provide
            each team member with their personalized digital business card.
          </div>
        </div>
      </div>
    </div>
  );
}
