import React from "react";
import Virtual from "../Images/VirtualBackground.png";

const VirtualBackground = () => {
  return (
    <div className="container" style={{marginBottom:"60px"}}>
    <div className="row">
      <div className="col-md-6 leading-none flex flex-col gap-[12px] virtualBackground py-8 ps-[150px]">
        <div
          className="mobMg-30 text-[#A2A2A2] text-[16px] leading-[20.8px] font-normal uppercase moTextCenter"
          data-aos="fade-up"
          data-aos-delay="50"
        >
          Modishcard backgrounds
        </div>
        <div
          className="text-[40px] leading-[52px]"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Professional{" "}
          <span className="text-[#0E77D3] font-bold">
            Virtual <br />
            Background
          </span>
        </div>
        <div
          className="w-[70%] text-[#909090] text-[16px] leading-[24px] tracking-wide moTextCenter"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          Transform every meeting into a polished display of your contact
          information. Modishcard’s backgrounds work all major videos
          conferencing platforms and help make an impressions that lasts beyond
          each all.
        </div>
      </div>

      <div className="col-md-6 flex items-end justify-center">
        <img src={Virtual} alt="" className="w-3/4" />
      </div>
    </div>
    </div>
  );
};

export default VirtualBackground;
