import React, { useState, useRef } from "react";
import Testimonalbg from "../Images/Testimonalbg.png";
import Testmonal1 from "../Images/Testmonal1.png";
import Testmonal2 from "../Images/Testmonal2.png";
import Testmonal4 from "../Images/Testmonal4.png";
import Testmonal3 from "../Images/Testemonal3.png";
import TextTestmonal3 from "../Images/TextTestmonal3.png";
import TextText4 from "../Images/TextText4.png";
import { RiArrowRightSFill, RiArrowLeftSFill } from "react-icons/ri";
import TestProfile from "../Images/TestProfile.png";
import SliderCardUser from "../Custom/SliderCardUser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Testimonials = () => {
  const data = [
    {
      name: "Samantha Sench",
      img: TestProfile,
      pro: "Student at University",
      p: `Lorem ipsum dolor sit amet consectetur. Iaculis rhoncus tempor
      vestibulum a. Aenean sed ipsum mollis quis odio eget. At amet
      nisi nec ultricies bibendum cursus amet justo. Vitae sapien
      faucibus et velit aliquet malesuada. Elementum ornare nisi
      elementum pellentesque tempus eget. Ut tortor aenean id mattis
      lorem. In rhoncus aliquam risus risus imperdiet aliquet
      convallis semper nunc.`,
    },
    {
      img: TestProfile,
      name: "Samantha Hii",
      pro: "Student at University",
      p: `Lorem ipsum dolor sit amet consectetur. Iaculis rhoncus tempor
      vestibulum a. Aenean sed ipsum mollis quis odio eget. At amet
      nisi nec ultricies bibendum cursus amet justo. Vitae sapien
      faucibus et velit aliquet malesuada. Elementum ornare nisi
      elementum pellentesque tempus eget. Ut tortor aenean id mattis
      lorem. In rhoncus aliquam risus risus imperdiet aliquet
      convallis semper nunc.`,
    },
    {
      img: TestProfile,
      name: "Gunjan",
      pro: "Student at University",
      p: `Lorem ipsum dolor sit amet consectetur. Iaculis rhoncus tempor
      vestibulum a. Aenean sed ipsum mollis quis odio eget. At amet
      nisi nec ultricies bibendum cursus amet justo. Vitae sapien
      faucibus et velit aliquet malesuada. Elementum ornare nisi
      elementum pellentesque tempus eget. Ut tortor aenean id mattis
      lorem. In rhoncus aliquam risus risus imperdiet aliquet
      convallis semper nunc.`,
    },
  ];

  const [activeBox, setActiveBox] = useState(0);
  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };

  const sortedData = [...data.slice(activeBox), ...data.slice(0, activeBox)];

  return (
    <div className="min-h-screen lg:h-[130vh] w-full bg-black relative" id="Testimonials">
      {/* Heading Section */}
      <div className="text-center text-white pt-[100px] lg:pt-[150px]">
        <div
          className="text-[#A2A2A2] text-sm lg:text-base uppercase font-semibold"
          data-aos="fade-up"
          data-aos-delay="50"
        >
          Testimonials
        </div>
        <div
          className="text-[28px] lg:text-[40px] leading-[36px] lg:leading-[52px] font-light"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Hear from Our{" "}
          <span className="text-[#0E77D3] font-bold">Satisfied Users</span>
        </div>
      </div>

      {/* Background and Images Section - Hidden on Mobile */}
      <div className="hidden lg:block absolute top-0 w-[35%] h-[120vh]">
        <img src={Testimonalbg} alt="" className="relative h-full" />
        <div className="flex text-[rgb(255,255,255)] absolute top-[31%] w-[70%] h-[75vh] justify-center right-0">
          <div className="absolute right-[-6%] top-[43%]" data-aos="fade-up">
            <img src={Testmonal1} alt="" />
          </div>
          <div className="absolute left-5 top-[38%]" data-aos="fade-up">
            <img src={Testmonal2} alt="" />
          </div>
          {/* Upper text */}
          <div className="absolute top-[13px] w-full">
            <div className="relative">
              <div className="absolute top-0 left-2">
                <img src={TextTestmonal3} alt="" className="h-[54px]" />
              </div>
              <div className="absolute left-[46%]" data-aos="fade-up">
                <img src={Testmonal3} alt="" />
              </div>
            </div>
          </div>
          {/* Bottom text */}
          <div className="absolute left-[46%] bottom-[11%]" data-aos="fade-up">
            <img src={Testmonal4} alt="" />
          </div>
          <div className="absolute left-[18%] bottom-[1%]">
            <img src={TextText4} alt="" className="h-[44px]" />
          </div>
        </div>
      </div>

      {/* Slider Section */}
      <div className="lg:w-1/2 w-full text-[#fff] absolute lg:right-0 px-4 lg:pe-[10%] py-[50px] lg:py-[100px]">
  <Slider
    ref={(slider) => {
      sliderRef = slider;
    }}
    {...settings}
  >
    {sortedData.map((item, index) => (
      <div key={index} className="flex flex-col items-center text-center">
        <div className="mb-4">
          <img src={item.img} alt={item.name} className="mx-auto rounded-full h-24 w-24" />
        </div>
        <div className="text-lg font-semibold">{item.name}</div>
        <div className="text-sm text-gray-400 mb-4">{item.pro}</div>
        <div className="text-sm leading-relaxed max-w-xs mx-auto text-justify">
          {item.p}
        </div>
      </div>
    ))}
  </Slider>

        {/* Arrow Buttons */}
        <div className="homeSlide-side mt-5 lg:col-span-6 mb-8 col-span-12 md:col-span-12 sm:col-span-12 flex flex-col">
          <div className="relative bottom-0 mt-8">
            <div className="flex gap-3 justify-center lg:justify-start">
              <button
                type="button"
                role="presentation"
                className="homeSlider-arrow-button hover:bg-[#fff]"
                onClick={previous}
              >
                <span aria-label="Previous">
                  <RiArrowLeftSFill className="hover:text-[#000] nwbtn" />
                </span>
              </button>
              <button
                type="button"
                role="presentation"
                className="homeSlider-arrow-button hover:bg-[#fff]"
                onClick={next}
              >
                <span aria-label="Next">
                  <RiArrowRightSFill className="hover:text-[#000] nwbtn" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
