import React from "react";
import modis from "../Images/Modishcard.png";
import phone1 from "../Images/phone1.png";
import phone2 from "../Images/phone2.png";

const Modishcard = () => {
  return (
    <div className="flex h-[70vh] ">
      <div className="flex flex-col w-[50%] justify-center items-start " >
        <div className="text-[40px] ps-[150px]" data-aos="fade-up" data-aos-delay="100">
          Getting Started with <br />
          <span className="text-[#0E77D3]" data-aos="fade-up" data-aos-delay="300">Modishcard</span>
        </div>
        <div className="ps-[80px]">
          <img src={modis} alt="" data-aos="fade-up" data-aos-delay="200"/>
        </div>
      </div>
      <div className="w-[50%] flex items-center relative">
        <div >
          <img src={phone1} alt="" className="absolute top-0"/>
        </div>
        <div >
          <img src={phone2} alt="" className="absolute bottom-0 right-[10%]"/>
        </div>
      </div>
    </div>
  );
};

export default Modishcard;
