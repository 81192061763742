import Logo from "../Images/Logo.svg";

const Footer = () => {
  return (
    <div className="bg-black">
    <div className="container">
    <div className="py-[100px] w-full  bg-black text-[white] ">
      <div className="row footerLink">
        <div className="col-md-3">
          <div>
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="col-md-9">
          <div className="text-[14px] font-normal ">
            <div className="row">
              <div className="col-md-2"><span>Contact Us</span></div>
              <div className="col-md-2"><span>FAQs</span></div>
              <div className="col-md-2"> <span>Privacy Policy</span></div>
              <div className="col-md-3"><span>Term of Service</span></div>
              <div className="col-md-3">  <span>© 2024 Modishcard</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>
  );
}

export default Footer;
