import Signature1 from "../Images/Signature1.png";
import Signature2 from "../Images/Signature2.png";
import Card2 from "../Images/Card2.png";

export default function Signatures() {
  return (
    <div className="bg-[#0A0A0A] h-screen flex  justify-center items-center   w-full gap-4 ps-[120px] moDis">
    <div className=" container">
    <div className="row" id="Signature">
      <div className="col-md-6 flex items-center relative h-[60vh]">
        <div className="absolute -bottom-20 left-1 z-10 ">
          <img src={Signature2} alt="" data-aos="zoom-in-right" className="h-[280px]"/>
        </div>
        <div className="absolute top-0 left-[28%]">
          <img src={Signature1} alt="" data-aos="zoom-in-up" className="h-[420px] "/>
        </div>

        <div className="absolute top-[23%] right-[3%] z-10">
          <img src={Card2} alt="" data-aos="zoom-in-left" className="h-[280px]"/>
        </div>
      </div>
      <div className="col-md-6 ">
        <div className=" ">
          <div className="leading-none flex flex-col gap-[12px] w-full SignatureBackground items-start py-[3rem]  ps-[5rem]">
            <div className="moTextCenter text-[#A2A2A2] text-[16px] leading-[20.8px] font-semibold uppercase" data-aos="fade-up" data-aos-delay="100">
              Modishcard email Signatures
            </div>
            <div className="text-[40px] leading-[52px] text-[white] font-light" data-aos="fade-up" data-aos-delay="300">
              Interactive{" "}
              <span className="text-[#0E77D3] font-bold">
                Email <br />
                Signatures
              </span>
            </div>
            <div className="moTextCenter w-[70%] text-[#909090] text-[16px] leading-[24px] tracking-wide font-normal" data-aos="fade-up" data-aos-delay="500">
              Centralize your company's networking with Modishcard. Gain control
              over brand consistency, manage contacts efficiently, and provide
              each team member with their personalized digital business card.
            </div>
          </div>
        </div>
      </div>
    </div>
    </div></div>
  );
}
