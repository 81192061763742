import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Box from "@mui/material/Box";
import CircularRectangel from "../Custom/CircularRectangel";
import GlanceImage from "../Images/GlanceImage.png";
import Feature1 from "../Images/Feature1.png";
gsap.registerPlugin(ScrollTrigger);

function OurFeatures() {
  const content = [
    {
      first: (
        <div className="flex items-center gap-3">
          AI <CircularRectangel className="bg-[#0E77D3] w-[7rem]" />{" "}
          <CircularRectangel className="bg-[#fff] w-10" />
        </div>
      ),
      second: "ASSISTANCE",
      third: `Navigate your professional world smarter with our AI assistant
        - schedule meetings, set reminders, and manage contacts
        effortlessly.`,
    },
    {
      first: "Customizable",
      second: (
        <div className="flex items-center gap-3">
          Designs <CircularRectangel className="bg-[#0E77D3] w-[7rem]" />{" "}
          <CircularRectangel className="bg-[#fff] w-10" />
        </div>
      ),
      third:
        "Reflect your brand's uniqueness with fully customizable card designs.",
    },
    {
      first: (
        <div className="flex items-center gap-3">
          Instant <CircularRectangel className="bg-[#0E77D3] w-[7rem]" />
        </div>
      ),
      second: (
        <div className="flex items-center gap-3">
          Sharing <CircularRectangel className="bg-[#fff] w-10" />
        </div>
      ),
      third:
        "Share your card with anyone, anywhere, through QR codes, email, or social media.",
    },
    {
      first: (
        <div className="flex items-center gap-3">
          Analytics <CircularRectangel className="bg-[#0E77D3] w-[7rem]" />{" "}
          <CircularRectangel className="bg-[#fff] w-10" />
        </div>
      ),
      third:
        "Track the impact of your card with detailed analytics, understanding how your network is growing.",
    },
    {
      first: (
        <div className="flex items-center gap-3">
          Elevate Your <CircularRectangel className="bg-[#fff] w-10" />
        </div>
      ),
      second: (
        <div className="flex items-center gap-3">
          Networking <CircularRectangel className="bg-[#0E77D3] w-[7rem]" />
        </div>
      ),
      third:
        "Now fully integrated with WhatsApp and Telegram marketing. This means your networking is not just smarter; it's boundless.",
    },
  ];

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set(".photo:not(:first-child)", { opacity: 0, scale: 0.5 });

      const animation = gsap.to(".photo:not(:first-child)", {
        opacity: 1,
        scale: 1,
        duration: 1,
        stagger: 1,
      });

      ScrollTrigger.create({
        trigger: ".main",
        start: "top top",
        end: "bottom bottom",
        pin: ".rightblock",
        animation: animation,
        scrub: true,
        markers: true,
      });

      ScrollTrigger.create({
        trigger: ".main",
        start: "top top",
        end: "bottom bottom",
        pin: ".blocks",
        scrub: true,
        markers: true,
      });
    });
    return () => ctx.revert();
  }, []);

  return (
    <div style={{width:"100%",display:"table",background:"#0a0a0a"}}>
    <div className="container">
      <div className="bg-[#0A0A0A] pb-[100px] pt-[100px] w-full">
        <div className="  main relative " id="OurFeatures">
          <Box className="flex-col " sx={{ display: "flex" }}>
            <div className="mo-pd-20 flex flex-col bg-[#0A0A0A] blocks w-full  top-0 z-10 justify-center items-center py-8">
              <div
                className="text-[#A2A2A2] text-base "
                data-aos="fade-up"
                data-aos-delay="100"
              >
                OUR FEATURES
              </div>
              <div
                className="text-4xl text-[#fff]  font-light tracking-wider"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <span className="text-[#0E77D3] font-bold text-[40px]">Features</span> at a
                Glance
              </div>
            </div>
            <div className="gallery flex gap-[80px]">
              <Box
                className="ps-[180px]"
                sx={{
                  width: "50%",
                  "& .details": {
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    color: "#000",
                    fontWeight: 900,
                  },
                }}
              >
                {content.map((item, index) => (
                  <Box key={index} className="details mo-pd-20">
                    <div
                      className="flex flex-col justify-start w-[100%]"
                      data-aos="zoom-in-up"
                    >
                      <div className="text-[64px] leading-[83px] text-[#fff] font-bold flex gap-2 items-center">
                        {item.first}
                      </div>
                      <div className="text-[64px] leading-[83px] text-[#fff] font-bold items-center">
                        {item.second}
                      </div>
                      <div className="text-[24px] leading-[36px] text-[#909090] font-light  tracking-wider flex gap-4 items-center mt-3">
                        {item.third}
                      </div>
                    </div>
                  </Box>
                ))}
              </Box>
              <Box
                className="rightblock items-center"
                sx={{
                  width: "50%",
                  height: "80vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <Box
                  sx={{
                    width: "26vw",
                    height: "90%",
                    position: "relative",
                    // right: "95px",

                    backgroundImage: `url(${GlanceImage})`,

                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    "& .photo": {
                      position: "absolute",
                      top: "24px",
                      width: "100%",
                      height: "98%",
                      right: "32px",

                      "& img": {
                        height: "89%",
                        width: "100%",
                        objectFit: "contain",
                      },
                    },
                  }}
                >
                  <Box className="photo">
                    <img src={Feature1} alt="img-1" />
                  </Box>
                  <Box className="photo">
                    <img src={Feature1} alt="img-1" />
                  </Box>
                  <Box className="photo">
                    <img src={Feature1} alt="img-1" />
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </div>
    </div></div>
  );
}

export default OurFeatures;
