import React from "react";
import Digital1 from "../Images/Digital1.png";
import Digital2 from "../Images/Digital2.png";

const Digital = () => {
  return (
    <div  className="h-screen Digital-background p-[100px]" id="WhyUs">
      <div className="text-center mobMg-30">
        <div className="text-[#A2A2A2] text-base font-normal mobMg-30" data-aos="fade-up" data-aos-delay="50">WHY US</div>
        <div className="whyUs leading-[52px]" data-aos="fade-up" data-aos-delay="300">
          Why Go <span className="text-[#0E77D3] font-bold">Digital</span> with
          Modishcard?
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mobMg-30">
          <img src={Digital1} alt="" data-aos="fade-up-left" className="whyUsImg"/>
        </div>
        <div  className="col-md-6 mobMg-30">
          <img src={Digital2} alt="" data-aos="fade-up-right" className="whyUsImg"/>
        </div>
      </div>
    </div>
  );
};

export default Digital;
