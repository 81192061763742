import { useEffect } from "react";
import Digital from "./Components/Digital";
import Header from "./Components/Header";
import OurFeatures from "./Components/OurFeatures";
import HeroSection from "./Components/HeroSection";
import WhyUs from "./Components/WhyUs";
import VirtualBackground from "./Components/VirtualBackground";
import Signatures from "./Components/Signatures";
import Team from "./Components/Team";
import Footer from "./Components/Footer";
import Modishcard from "./Components/Modishcard";
// Animation package
import Aos from "aos";
import "aos/dist/aos.css";
import Testimonials from "./Components/Testimonials";
function App() {
  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 120,
      // disable: "mobile",
    });
  }, []);
  return (
    <div className="mainContainer" >
      <Header />
      <HeroSection />
     <WhyUs />
     <Digital />
         <OurFeatures />
  <VirtualBackground />
  <Signatures />
           <Team />
      <Testimonials />
      <Modishcard />
      <Footer /> 

    </div>
  );
}

export default App;
