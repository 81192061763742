import React, { useState } from "react";
import network1 from "../Images/Networkingimg1.png";
import network2 from "../Images/Networkingimg2.png";
import network3 from "../Images/Networkingimg3.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const WhyUs = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [network1, network2, network3];

  const handleNextClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const handlePrevClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  };

  const getSlideClass = (index) => {
    if (index === currentSlide) {
      return "slide active";
    } else if (
      index === (currentSlide - 1 + images.length) % images.length ||
      index === (currentSlide + 1) % images.length
    ) {
      return "slide adjacent";
    } else {
      return "slide";
    }
  };

  return (
    <div className="bg-black flex flex-col p-[100px] gap-16 relative w-full lg:p-[100px] lg:gap-16">
      <div className="text-white flex flex-col gap-2 justify-center items-center">
        <div className="mt-10 text-base uppercase font-medium text-[#A2A2A2]" data-aos="fade-up" data-aos-delay="50">
          What Makes Modishcard Unique?
        </div>
        <div className="text-[28px] leading-[36px] lg:text-[40px] lg:leading-[52px]" data-aos="fade-up" data-aos-delay="200">
          Innovative{" "}
          <span className="text-[#0E77D3] font-bold">Networking</span> at Your Fingertips
        </div>
        <div className="w-[90%] text-center text-[#909090] text-base font-normal lg:w-[70%]" data-aos="fade-up" data-aos-delay="400">
          Modishcard is not just a digital card; it's a networking powerhouse.
          It's where cutting-edge AI meets elegant design, offering you a smart
          and sophisticated way to share your professional identity.
        </div>
      </div>

      {/* Image Slider */}
      <div className="relative flex justify-center items-center mt-10">
        <div className="slider-container relative flex justify-center items-center">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Network Slide ${index}`}
              className={`${getSlideClass(index)} object-cover ${
                index === currentSlide
                  ? "w-[80%] max-h-[300px] scale-110"
                  : "hidden"
              } lg:block lg:w-auto lg:max-w-[300px] lg:max-h-[500px] lg:scale-100 lg:transform-none lg:relative lg:z-10`}
              style={{
                transition: "all 0.3s ease",
                position: index === currentSlide ? "relative" : "absolute",
              }}
            />
          ))}
        </div>
        {/* Navigation Buttons */}
        <button
          onClick={handlePrevClick}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-700 text-white rounded-full"
        >
          <MdKeyboardArrowLeft size={30} />
        </button>
        <button
          onClick={handleNextClick}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-700 text-white rounded-full"
        >
          <MdKeyboardArrowRight size={30} />
        </button>
      </div>
    </div>
  );
};

export default WhyUs;
